import React, { Component } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import axios from 'axios';

class GetStarted extends Component {
  constructor (props) {
    super(props);

    this.state = {
      isSubmitted: false,
      formPackage: '',
      formName: '',
      formEmail: '',
      isError: false,
      isProcessing: false,
      errorMsg: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  encode (data) {
    return Object.keys(data)
      .map(
        key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
      )
      .join('&');
  }

  handleChange (e) {
    let newState = {};

    newState[e.target.name] = e.target.value;

    this.setState(newState);
  }

  handleSubmit (e, message) {
    e.preventDefault();

    if (this.state.isError) {
      this.setState({
        isError: false
      });
    }

    this.setState({
      isProcessing: true
    });

    // let formData = {
    //   package: this.state.formPackage,
    //   name: this.state.formName,
    //   email: this.state.formEmail
    // };
    //
    // if (formData.package.length < 1 || formData.email.length < 1) {
    //   return false;
    // }
    //
    // axios({
    //   method: 'post',
    //   url: '/',
    //   headers: { 'content-type': 'application/x-www-form-urlencoded' },
    //   data: JSON.stringify(formData)
    // })

    const form = e.target;

    axios.post('/',
      this.encode({
        'form-name': form.getAttribute('name'),
        ...this.state
      }),
      {
        header: { 'Content-Type:': 'application/x-www-form-urlencoded' }
      }
    )
      .then(() => {
        this.setState({
          isSubmitted: true,
          isProcessing: false
        });
      })
      .catch(error => {
        this.setState({
          isError: true,
          errorMsg: error.message,
          isProcessing: false
        });
      });
  }

  componentDidUpdate (prevProps) {
    if (this.props.packageSelected && this.props.packageSelected !== prevProps.packageSelected) {
      this.setState({
        formPackage: this.props.packageSelected
      });
    }
  }

  render () {
    return (
      <section className="getstarted">
        {this.state.isSubmitted ? (
          <>
          <h1 className="getstarted__heading">We look forward to working together!</h1>
          <p>We will be in touch soon.</p>
          </>
        ) : (
          <>
          <h1 className="getstarted__heading">We’ve helped our clients get the most out of social media</h1>
          <p>Fill out the form below to pick a package and schedule a call with us.</p>
          <form className="getstarted__form" name="get-started" onSubmit={this.handleSubmit} data-netlify="true" data-netlify-honeypot="formField">
            <input type="hidden" name="form-name" value={'Automated Social Lead'} />
            <div className="input-grp input-grp--initial">
              <label htmlFor="getstartedField">Keep Empty</label>
              <input id="getstartedField" name="formField" type="text" onChange={this.handleChange} />
            </div>
            <div className="input-grp">
              <label htmlFor="getstartedPackage">Package <span className="label__link">(<Link to="/services">view packages</Link>)</span></label>
              <div className="input-grp__select">
                <select id="getstartedPackage" name="formPackage" onChange={this.handleChange} value={this.state.formPackage} required>
                  <option value="">Select a package</option>
                  {this.props.packageData.map((service, index) =>
                    <option key={index} value={service.node.data.name.text}>{service.node.data.name.text}</option>
                  )}
                </select>
              </div>
            </div>
            <div className="input-grp">
              <label htmlFor="getstartedName">Name</label>
              <input id="getstartedName" name="formName" type="text" onChange={this.handleChange} value={this.state.formName} />
            </div>
            <div className="input-grp">
              <label htmlFor="getstartedEmail">Email</label>
              <input id="getstartedEmail" name="formEmail" type="email" onChange={this.handleChange} value={this.state.formEmail} required />
            </div>
            <button className="cta cta--primary getstarted__submit" type="submit" disabled={this.state.isProcessing}>{this.state.isProcessing ? 'Sending...' : 'Send'}</button>
            {this.state.isError &&
              <div className="error-grp">
                <p>There was a problem sending your request. Please try again later.</p>
              </div>
            }
          </form>
          </>
        )}
      </section>
    );
  }
}

GetStarted.propTypes = {
  packageData: PropTypes.array.isRequired,
  packageSelected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ])
};

export default GetStarted;
