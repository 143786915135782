import React, { Component } from 'react';
import Layout from '../components/Layout/';
import Seo from '../components/Seo/';
import GetStarted from '../components/GetStarted/';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

class GetStartedPage extends Component {
  constructor (props) {
    super(props);

    this.state = {
      packageSelected: false
    };
  }

  componentDidMount () {
    if (this.props.location.state) {
      const serviceSelected = this.props.location.state.serviceSelected ? this.props.location.state.serviceSelected : false;

      this.setState({
        packageSelected: serviceSelected
      });
    }
  }

  render () {
    return (
      <Layout>
        <Seo title="Get Started" keywords={[`social media automation`, `health coach`, `social engagement`]} />
        <GetStarted packageData={this.props.data.allPrismicPackages.edges} packageSelected={this.state.packageSelected} />
      </Layout>
    );
  }
}

export const query = graphql`
  {
    allPrismicPackages( filter: { uid: { ne: "do-not-delete-this-20190214"} } ) {
      edges {
        node {
          data {
            name {
              text
            }
            description {
              text
            }
            price
          }
        }
      }
    }
  }
`;

GetStartedPage.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object.isRequired
};

export default GetStartedPage;
